<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:vectornator="http://vectornator.io"
    style="
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
    xml:space="preserve"
    version="1.1"
    viewBox="8.73 9.74 169.48 220.9"
  >
    <defs></defs>
    <g id="Icon" vectornator:layerName="Icon">
      <path
        d="M38.3185+9.73879L38.3185+142.747C38.3185+158.372+41.2482+171.36+47.1076+181.711C55.799+197.336+70.4474+205.149+91.0529+205.149C115.76+205.149+132.557+196.702+141.444+179.807C146.229+170.627+148.621+158.274+148.621+142.747L148.621+107.739L178.211+107.739L178.211+130.588C178.211+157.053+174.647+177.415+167.518+191.672C154.432+217.649+129.725+230.637+93.3966+230.637C57.0685+230.637+32.4103+217.649+19.422+191.672C12.2931+177.415+8.72866+157.053+8.72866+130.588L8.72866+9.73879L38.3185+9.73879Z"
        opacity="1"
        fill="#14d2d5"
      ></path>
      <path
        d="M147.595+70.6467C147.595+62.4266+154.259+55.7629+162.479+55.7629C170.699+55.7629+177.363+62.4266+177.363+70.6467C177.363+78.8667+170.699+85.5304+162.479+85.5304C154.259+85.5304+147.595+78.8667+147.595+70.6467Z"
        opacity="1"
        fill="#14d2d5"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'logo-icon',
};
</script>

<style></style>
